
export const PagePath = [
    {
        "id": "machines",
        "ru": "оборудование-для-монтажа",
        "ua": "ua/обладнання-для-монтажу",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-printer",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-printer-small",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/принтеры-пасты-ручные",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/принтери-пасти-ручні",
        "en": "en/machines",
        "lang": "dadaasds"
    },
    {
        "id": "machines-smt-printer-small-sd240",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/принтеры-пасты-ручные/sd240",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/принтери-пасти-ручні/sd240",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-printer-small-sd300",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/принтеры-пасты-ручные/sd300",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/принтери-пасти-ручні/sd300",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-printer-small-sd360-u",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/принтеры-пасты-ручные/sd360-u",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/принтери-пасти-ручні/sd360-u",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-printer-small-uniprint-s",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/принтеры-пасты-ручные/uniprint-s",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/принтери-пасти-ручні/uniprint-s",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-printer-small-uniprint-v",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/принтеры-пасты-ручные/uniprint-v",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/принтери-пасти-ручні/uniprint-v",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-printer-small-uniprint-m",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/принтеры-пасты-ручные/uniprint-m",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/принтери-пасти-ручні/uniprint-m",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-printer-semi",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/полуавтоматический-принтер",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/напівавтоматичний-принтер",
        "en": "en/machines",
        "lang": "dadaasds"
    },
    {
        "id": "machines-smt-printer-semi-go23",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/полуавтоматический-принтер/go23",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/напівавтоматичний-принтер/go23",
        "en": "en/machines",
        "lang": "dadaasds"
    },
    {
        "id": "machines-smt-printer-semi-go29",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/полуавтоматический-принтер/go29",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/напівавтоматичний-принтер/go29",
        "en": "en/machines",
        "lang": "dadaasds"
    },
    {
        "id": "machines-smt-printer-semi-goled",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/полуавтоматический-принтер/goled",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/напівавтоматичний-принтер/goled",
        "en": "en/machines",
        "lang": "dadaasds"
    },
    {
        "id": "machines-smt-printer-semi-fa23",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/полуавтоматический-принтер/fa23",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/напівавтоматичний-принтер/fa23",
        "en": "en/machines",
        "lang": "dadaasds"
    },
    {
        "id": "machines-smt-printer-auto",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/автоматический-принтер-пасты",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/автоматичний-принтер-пасти",
        "en": "en/machines",
        "lang": "dadaasds"
    },
    {
        "id": "machines-smt-printer-auto-g-titan",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/автоматический-принтер-пасты/g-titan",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/автоматичний-принтер-пасти/g-titan",
        "en": "en/machines",
        "lang": "dadaasds"
    },
    {
        "id": "machines-smt-printer-auto-pmaxii",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/автоматический-принтер-пасты/pmaxii",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/автоматичний-принтер-пасти/pmaxii",
        "en": "en/machines",
        "lang": "dadaasds"
    },
    {
        "id": "machines-smt-printer-auto-p-primo",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/автоматический-принтер-пасты/p-primo",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/автоматичний-принтер-пасти/p-primo",
        "en": "en/machines",
        "lang": "dadaasds"
    },
    {
        "id": "machines-smt-printer-auto-kayo-450",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/принтеры-паяльной-пасты/автоматический-принтер-пасты/kayo-450",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/принтери-паяльної-пасти/автоматичний-принтер-пасти/kayo-450",
        "en": "en/machines",
        "lang": "dadaasds"
    },

    {
        "id": "machines-smt-placer",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-manipulator",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/манипулятор-для-smd",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/маніпулятор-встановлення-smd",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-manipulator-lm900",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/манипулятор-для-smd/lm900",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/маніпулятор-встановлення-smd/lm900",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-manipulator-lm901",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/манипулятор-для-smd/lm901",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/маніпулятор-встановлення-smd/lm901",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-manipulator-sm902",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/манипулятор-для-smd/sm902",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/маніпулятор-встановлення-smd/sm902",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-manipulator-sm902-professional",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/манипулятор-для-smd/sm902pro",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/маніпулятор-встановлення-smd/sm902pro",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-toptable",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/расстановщик-smd",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/розстановник-smd",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-toptable-kayo-a4",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/расстановщик-smd/kayo-a4",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/розстановник-smd/kayo-a4",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-toptable-kayo-d2v-25s",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/расстановщик-smd/kayo-d2v-25s",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/розстановник-smd/kayo-d2v-25s",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-toptable-kayo-a6l",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/расстановщик-smd/kayo-a6l",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/розстановник-smd/kayo-a6l",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-toptable-kayo-a8l",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/расстановщик-smd/kayo-a6l",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/розстановник-smd/kayo-a8l",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-semi",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/автоматические-уст-smd",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/автоматичні-установники-smd",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-semi-placeall-520",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/автоматические-уст-smd/placeall-520",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/автоматичні-установники-smd/placeall-520",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-semi-placeall-620",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/автоматические-уст-smd/placeall-620",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/автоматичні-установники-smd/placeall-620",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-semi-placeall-620l",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/автоматические-уст-smd/placeall-620l",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/автоматичні-установники-smd/placeall-620l",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-semi-placeall-610xl",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/автоматические-уст-smd/placeall-610xl",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/автоматичні-установники-smd/placeall-610xl",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-juki",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/установщик-компонентов",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/установник-компонентів",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-juki-rs-1",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/установщик-компонентов/rs-1-juki",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/установник-компонентів/rs-1-juki",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-juki-rs-1r",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/установщик-компонентов/rs-1r-juki",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/установник-компонентів/rs-1r-juki",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-juki-rs-1xl",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/установщик-компонентов/rs-1xl-juki",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/установник-компонентів/rs-1xl-juki",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-juki-rx",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/скоростной-автомат-smt",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/швидкісний-автомат",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-juki-rx-6",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/скоростной-автомат-smt/rx-6-juki",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/швидкісний-автомат/rx-6-juki",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-juki-rx-7",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/скоростной-автомат-smt/rx-7-juki",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/швидкісний-автомат/rx-7-juki",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-juki-rx-8",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/скоростной-автомат-smt/rx-8-juki",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/швидкісний-автомат/rx-8-juki",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-juki-rx-8-1",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/скоростной-автомат-smt/rx-8-1-juki",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/швидкісний-автомат/rx-8-1-juki",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-juki-rx-7r",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/скоростной-автомат-smt/rx-7r-juki",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/швидкісний-автомат/rx-7r-juki",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-juki-fx",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/smd-монтажный-автомат",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/smd-монтажний-автомат",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-juki-fx-3",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/smd-монтажный-автомат/fx-3-juki",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/smd-монтажний-автомат/fx-3-juki",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-juki-jx",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/установщик-smd",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/установник-smd",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-juki-jx-350",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматы-установки-компонентов/установщик-smd/jx-350-juki",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомати-встановлення-компонентів/установник-smd/jx-350-juki",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-placer-juki-all",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автомат-поверхностного-монтажа",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автомат-поверхневого-монтажу",
        "en": "en/machines",
        "lang": ""
    },

    {
        "id": "machines-smt-reflow",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные/печи-для-пайки-smd",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні/печі-для-пайки-smd",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-reflow-spide-ha06",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные/печи-для-пайки-smd/ha06",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні/печі-для-пайки-smd/ha06",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-reflow-spide-mistral-260",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные/печи-для-пайки-smd/mistral-260",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні/печі-для-пайки-smd/mistral-260",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-reflow-spide-mistral-360",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные/печи-для-пайки-smd/mistral-360",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні/печі-для-пайки-smd/mistral-360",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-reflow-kayo",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные/конвекционные-печи-оплавления",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні/конвекційні-печі-оплавлення",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-reflow-kayo-rf-430",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные/конвекционные-печи-оплавления/rf-430",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні/конвекційні-печі-оплавлення/rf-430",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-reflow-kayo-rf-630",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные/конвекционные-печи-оплавления/rf-630",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні/конвекційні-печі-оплавлення/rf-630",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-reflow-kayo-rf-835",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные/конвекционные-печи-оплавления/rf-835",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні/конвекційні-печі-оплавлення/rf-835",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-reflow-juki",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные/конвекционная-пайка",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні/конвекційна-пайка",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-reflow-juki-rs-600",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные/конвекционная-пайка/rs-600",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні/конвекційна-пайка/rs-600",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-reflow-juki-rs-800",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные/конвекционная-пайка/rs-800",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні/конвекційна-пайка/rs-800",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-reflow-juki-rs-1000",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные/конвекционная-пайка/rs-1000",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні/конвекційна-пайка/rs-1000",
        "en": "en/machines",
        "lang": ""
    },




    {
        "id": "machines-smt-reflow",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/печи-паяльные",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/печі-паяльні",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-spi",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/инспекция-паяльной-пасты",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/інспекція-паяльної-пасти",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-aoi",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматическая-оптическая-инспекция",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автоматична-оптична-інспекція",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-conveyer",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат",
        "en": "en/machines",
        "lang": ""
    },

    {
        "id": "machines-tht",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-tht-insertion",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия/установщик-компонентов-в-отверстия",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори/установник-компонентів-в-отвори",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-tht-insertion-juki-jm-100",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия/установщик-компонентов-в-отверстия/jm-100-juki",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори/установник-компонентів-в-отвори/jm-100-juki",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-tht-insertion-juki-jm-20",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия/установщик-компонентов-в-отверстия/jm-20-juki",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори/установник-компонентів-в-отвори/jm-20-juki",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-tht-insertion-juki-jm-50",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия/установщик-компонентов-в-отверстия/jm-50-juki",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори/установник-компонентів-в-отвори/jm-50-juki",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-tht-selective",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия/селективная-пайка",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори/селективне-паяння",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-tht-selective-off",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия/установки-селективной-пайки",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори/установки-селективного-паяння",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-tht-selective-off-inertec-cube-460",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия/установки-селективной-пайки/cube-460",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори/установки-селективного-паяння/cube-460",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-tht-selective-in",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия/система-селективной-пайки",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори/система-селективного-паяння",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-tht-selective-inertec-in-cube-inline",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия/система-селективной-пайки/cube-inline",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори/система-селективного-паяння/cube-inline",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-tht-selective-entry",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия/оборудование-для-селективной-пайки",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори/обладнання-для-селективної-пайки",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-tht-selective-entry-top-375ce",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия/оборудование-для-селективной-пайки/top-375ce",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори/обладнання-для-селективної-пайки/top-375ce",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-tht-wave",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия/пайка-волной-припоя",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори/пайка-хвилею-припою",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-tht-wave-entry",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия/установки-пайки-волной",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори/установки-паяння-хвилею",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-tht-wave-entry-core-sig",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия/установки-пайки-волной/core-sig",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори/установки-паяння-хвилею/core-sig",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-tht-wave-entry-miniwaver-sig",
        "ru": "оборудование-для-монтажа/монтаж-в-отверстия/установки-пайки-волной/miniwaver-sig",
        "ua": "ua/обладнання-для-монтажу/монтаж-в-отвори/установки-паяння-хвилею/miniwaver-sig",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-inspection-spi",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/инспекция-паяльной-пасты/инспекция-нанесения-пасты",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/інспекція-паяльної-пасти/інспекція-нанесення-паяльної-пасти",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-inspection-spi-rv-2",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/инспекция-паяльной-пасты/инспекция-нанесения-пасты/rv-2-spi",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/інспекція-паяльної-пасти/інспекція-нанесення-паяльної-пасти/rv-2-spi",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-inspection-spi-rv-2-3dh",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/инспекция-паяльной-пасты/инспекция-нанесения-пасты/rv-2-3dh-spi",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/інспекція-паяльної-пасти/інспекція-нанесення-паяльної-пасти/rv-2-3dh-spi",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-inspection-spi-rv-2-3d",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/инспекция-паяльной-пасты/инспекция-нанесения-пасты/rv-2-3d-spi",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/інспекція-паяльної-пасти/інспекція-нанесення-паяльної-пасти/rv-2-3d-spi",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-inspection-aoi",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматическая-оптическая-инспекция/системы-инспекции",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автоматична-оптична-інспекція/системи-оптичної-інспекції",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-inspection-aoi-rv-2",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматическая-оптическая-инспекция/системы-инспекции/rv-2-aoi",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автоматична-оптична-інспекція/системи-оптичної-інспекції/rv-2-aoi",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-inspection-aoi-rv-2-3d",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматическая-оптическая-инспекция/системы-инспекции/rv-2-3d-aoi",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автоматична-оптична-інспекція/системи-оптичної-інспекції/rv-2-3d-aoi",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-inspection-aoi-rv-2-3dh",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматическая-оптическая-инспекция/системы-инспекции/rv-2-3dh-aoi",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автоматична-оптична-інспекція/системи-оптичної-інспекції/rv-2-3dh-aoi",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-inspection-aoi-vcta",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматическая-оптическая-инспекция/инспекция-плат",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автоматична-оптична-інспекція/оптична-інспекція-плат",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-inspection-aoi-vcta-a410",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/автоматическая-оптическая-инспекция/инспекция-плат/vcta-a410",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/автоматична-оптична-інспекція/оптична-інспекція-плат/vcta-a410",
        "en": "en/machines",
        "lang": ""
    },

    {
        "id": "machines-smt-loaderunloader",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/загрузчик-разгрузчик-плат",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/завантажувач-розвантажувач-плат",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-conveyers-mb814-light",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/загрузчик-разгрузчик-плат/mb814",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/завантажувач-розвантажувач-плат/mb814",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-loaderunloader-mb815-light",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/загрузчик-разгрузчик-плат/mb815",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/завантажувач-розвантажувач-плат/mb815",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-loaderunloader-mb802",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/загрузчик-разгрузчик-плат/mb802",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/завантажувач-розвантажувач-плат/mb802",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-loaderunloader-mb801",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/загрузчик-разгрузчик-плат/mb801",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/завантажувач-розвантажувач-плат/mb801",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-loaderunloader-mb760",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/загрузчик-разгрузчик-плат/mb760",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/завантажувач-розвантажувач-плат/mb760",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-loaderunloader-mb761",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/загрузчик-разгрузчик-плат/mb716",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/завантажувач-розвантажувач-плат/mb761",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-loaderunloader-mb817",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/загрузчик-разгрузчик-плат/mb817",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/завантажувач-розвантажувач-плат/mb817",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-loaderunloader-kayo-50p",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/загрузчик-разгрузчик-плат/kayo-50p",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/завантажувач-розвантажувач-плат/kayo-50p",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-loaderunloader-kayo-50s",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/загрузчик-разгрузчик-плат/kayo-50s",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/завантажувач-розвантажувач-плат/kayo-50s",
        "en": "en/machines",
        "lang": ""
    },

    {
        "id": "machines-smt-conveyer",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/конвейеры-для-печатных-плат",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/конвеєри-для-друкованих-плат",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-conveyer-lc-light",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/конвейеры-для-печатных-плат/lc",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/конвеєри-для-друкованих-плат/lc",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-conveyer-mb",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/конвейеры-для-печатных-плат/mb",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/конвеєри-для-друкованих-плат/mb",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-conveyer-hl",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/конвейеры-для-печатных-плат/hl",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/конвеєри-для-друкованих-плат/hl",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-conveyer-kayo-ic",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/конвейеры-для-печатных-плат/ic",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/конвеєри-для-друкованих-плат/ic",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-conveyer-mb-workstation",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/конвейеры-для-печатных-плат/workstation",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/конвеєри-для-друкованих-плат/workstation",
        "en": "en/machines",
        "lang": ""
    },

    {
        "id": "machines-smt-buffer",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/конвейерные-системы-для-плат",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/конвеєрні-системи-для-плат",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-buffer-mb785",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/конвейерные-системы-для-плат/mb785",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/конвеєрні-системи-для-плат/mb785",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-buffer-mb750",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/конвейерные-системы-для-плат/mb750",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/конвеєрні-системи-для-плат/mb750",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-buffer-mb805",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/конвейерные-системы-для-плат/mb805",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/конвеєрні-системи-для-плат/mb805",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-smt-buffer-mb780",
        "ru": "оборудование-для-монтажа/поверхностный-монтаж/перемещение-печатных-плат/конвейерные-системы-для-плат/mb780",
        "ua": "ua/обладнання-для-монтажу/поверхневий-монтаж/переміщення-друкованих-плат/конвеєрні-системи-для-плат/mb780",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття",
        "en": "en/machines",
        "lang": ""
    },

    {
        "id": "machines-cleaning-pcb",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/отмывка-печатных-плат",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/відмивання-друкованих-плат",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning-pcb-hyperswash",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/отмывка-печатных-плат/hyperswash",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/відмивання-друкованих-плат/hyperswash",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning-pcb-superswash",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/отмывка-печатных-плат/superswash",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/відмивання-друкованих-плат/superswash",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning-pcb-miniswash",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/отмывка-печатных-плат/miniswash",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/відмивання-друкованих-плат/miniswash",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning-pcb-compaclean",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/отмывка-печатных-плат/compaclean",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/відмивання-друкованих-плат/compaclean",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning-pcb-uniclean",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/отмывка-печатных-плат/uniclean",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/відмивання-друкованих-плат/uniclean",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning-pcb-uniclean-xlarge",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/отмывка-печатных-плат/uniclean-xlarge",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/відмивання-друкованих-плат/uniclean-xlarge",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning-stencil",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/отмывка-трафаретов",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/відмивання-трафаретів",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning-stencil-hyperswash",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/отмывка-трафаретов/hyperswash",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/відмивання-трафаретів/hyperswash",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning-stencil-superswash",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/отмывка-трафаретов/superswash",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/відмивання-трафаретів/superswash",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning-stencil-miniswash",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/отмывка-трафаретов/miniswash",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/відмивання-трафаретів/miniswash",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning-stencil-gensonic",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/отмывка-трафаретов/gensonic",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/відмивання-трафаретів/gensonic",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning-pallete",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/мойка-паллет-кареток",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/миття-палет-кареток",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning-pallete-compaclean",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/мойка-паллет-кареток/compaclean",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/миття-палет-кареток/compaclean",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning-pallete-fluxclean-t4",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/мойка-паллет-кареток/fluxclean-t4",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/миття-палет-кареток/fluxclean-t4",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cleaning-pallete-fluxclean-sd",
        "ru": "оборудование-для-монтажа/оборудование-для-мойки/мойка-паллет-кареток/fluxclean-sd",
        "ua": "ua/обладнання-для-монтажу/обладнання-для-миття/миття-палет-кареток/fluxclean-sd",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-selective",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/селективная-влагозащита",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/селективний-вологозахист",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-selective-pva-delta-8",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/селективная-влагозащита/delta-8",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/селективний-вологозахист/delta-8",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-selective-pva-delta-6",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/селективная-влагозащита/delta-6",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/селективний-вологозахист/delta-6",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-selective-pva-pva350",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/селективная-влагозащита/pva350",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/селективний-вологозахист/pva350",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-selective-pva-sigma",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/селективная-влагозащита/sigma",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/селективний-вологозахист/sigma",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-dip",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/влагозащита-погружением",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/вологозахист-зануренням",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-dip-gem3-dc-2000",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/влагозащита-погружением/dc-2000",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/вологозахист-зануренням/dc-2000",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-dip-gem3-dc-2002",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/влагозащита-погружением/dc-2002",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/вологозахист-зануренням/dc-2002",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-spray",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/нанесение-защитных-покрытий",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/нанесення-захисних-покриттів",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-spray-mccs-sys-1",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/нанесение-защитных-покрытий/mccs-sys-1",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/нанесення-захисних-покриттів/mccs-sys-1",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-spray-mccs-sys-2",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/нанесение-защитных-покрытий/mccs-sys-2",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/нанесення-захисних-покриттів/mccs-sys-2",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-spray-fcs300-r-2lt-h",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/нанесение-защитных-покрытий/fcs300-r-2lt-h",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/нанесення-захисних-покриттів/fcs300-r-2lt-h",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-curing",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/оборудование-для-отверждения",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/обладнання-для-полімеризації",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-curing-deltatherm",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/оборудование-для-отверждения/deltatherm",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/обладнання-для-полімеризації/deltatherm",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-curing-spectra",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/оборудование-для-отверждения/spectra",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/обладнання-для-полімеризації/spectra",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-conveyer",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/конвейеры-защитных-покрытий",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/конвеєри-захисних-покриттів",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-conveyer-cycc460",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/конвейеры-защитных-покрытий/cycc460",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/конвеєри-захисних-покриттів/cycc460",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-conveyer-queue",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/конвейеры-защитных-покрытий/queue",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/конвеєри-захисних-покриттів/queue",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-conveyer-bi2000",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/конвейеры-защитных-покрытий/bi2000",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/конвеєри-захисних-покриттів/bi2000",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-aoi",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/инспекция-нанесения-защитных-покрытий",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/інспекція-нанесення-захисних-покриттів",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-coating-aoi-pva-20-20",
        "ru": "оборудование-для-монтажа/влагозащита-электроники/инспекция-нанесения-защитных-покрытий/pva-20-20",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки/інспекція-нанесення-захисних-покриттів/pva-20-20",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cutting",
        "ru": "оборудование-для-монтажа/разделение-групповых-заготовок",
        "ua": "ua/обладнання-для-монтажу/розділення-групових-панелей",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cutting-cutter",
        "ru": "оборудование-для-монтажа/разделение-групповых-заготовок/устройства-разделения-печатных-плат",
        "ua": "ua/обладнання-для-монтажу/розділення-групових-панелей/пристрої-розділення-друкованих-плат",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cutting-cutter-maestro-2",
        "ru": "оборудование-для-монтажа/разделение-групповых-заготовок/устройства-разделения-печатных-плат/maestro-2",
        "ua": "ua/обладнання-для-монтажу/розділення-групових-панелей/пристрої-розділення-друкованих-плат/maestro-2",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cutting-cutter-maestro-2m",
        "ru": "оборудование-для-монтажа/разделение-групповых-заготовок/устройства-разделения-печатных-плат/maestro-2m",
        "ua": "ua/обладнання-для-монтажу/розділення-групових-панелей/пристрої-розділення-друкованих-плат/maestro-2m",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cutting-cutter-maestro-3e",
        "ru": "оборудование-для-монтажа/разделение-групповых-заготовок/устройства-разделения-печатных-плат/maestro-3e",
        "ua": "ua/обладнання-для-монтажу/розділення-групових-панелей/пристрої-розділення-друкованих-плат/maestro-3e",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cutting-cutter-maestro-4s",
        "ru": "оборудование-для-монтажа/разделение-групповых-заготовок/устройства-разделения-печатных-плат/maestro-4s",
        "ua": "ua/обладнання-для-монтажу/розділення-групових-панелей/пристрої-розділення-друкованих-плат/maestro-4s",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cutting-cutter-maestro-5l",
        "ru": "оборудование-для-монтажа/разделение-групповых-заготовок/устройства-разделения-печатных-плат/maestro-5l",
        "ua": "ua/обладнання-для-монтажу/розділення-групових-панелей/пристрої-розділення-друкованих-плат/maestro-5l",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cutting-cutter-maestro-6",
        "ru": "оборудование-для-монтажа/разделение-групповых-заготовок/устройства-разделения-печатных-плат/maestro-6",
        "ua": "ua/обладнання-для-монтажу/розділення-групових-панелей/пристрої-розділення-друкованих-плат/maestro-6",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cutting-cutter-hektor-2",
        "ru": "оборудование-для-монтажа/разделение-групповых-заготовок/устройства-разделения-печатных-плат/hektor-2",
        "ua": "ua/обладнання-для-монтажу/розділення-групових-панелей/пристрої-розділення-друкованих-плат/hektor-2",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cutting-router",
        "ru": "оборудование-для-монтажа/разделение-групповых-заготовок/оборудование-разделения-печатных-плат",
        "ua": "ua/обладнання-для-монтажу/розділення-групових-панелей/обладнання-розділення-друкованих-плат",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cutting-router-er-6000",
        "ru": "оборудование-для-монтажа/разделение-групповых-заготовок/оборудование-разделения-печатных-плат/er-6000",
        "ua": "ua/обладнання-для-монтажу/розділення-групових-панелей/обладнання-розділення-друкованих-плат/er-6000",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cutting-router-er-8000",
        "ru": "оборудование-для-монтажа/разделение-групповых-заготовок/оборудование-разделения-печатных-плат/er-8000",
        "ua": "ua/обладнання-для-монтажу/розділення-групових-панелей/обладнання-розділення-друкованих-плат/er-8000",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cutting-laser",
        "ru": "оборудование-для-монтажа/разделение-групповых-заготовок/разделение-групповых-заготовок-лазером",
        "ua": "ua/обладнання-для-монтажу/розділення-групових-панелей/розділення-групових-заготовок-лазером",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cutting-laser-lba40g",
        "ru": "оборудование-для-монтажа/разделение-групповых-заготовок/разделение-групповых-заготовок-лазером/lba40g",
        "ua": "ua/обладнання-для-монтажу/розділення-групових-панелей/розділення-групових-заготовок-лазером/lba40g",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-cutting-laser-lbb40g",
        "ru": "оборудование-для-монтажа/разделение-групповых-заготовок/разделение-групповых-заготовок-лазером/lbb40g",
        "ua": "ua/обладнання-для-монтажу/розділення-групових-панелей/розділення-групових-заготовок-лазером/lbb40g",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage-storage",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения/автоматизированные-склады-компонентов",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/автоматизовані-склади-компонентів",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage-storage-ism-ultraflex-3600",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения/автоматизированные-склады-компонентов/ism-ultraflex-3600",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/автоматизовані-склади-компонентів/ism-ultraflex-3600",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage-storage-ism-2000",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения/автоматизированные-склады-компонентов/ism-2000",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/автоматизовані-склади-компонентів/ism-2000",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage-storage-ism-1800",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения/автоматизированные-склады-компонентов/ism-1800",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/автоматизовані-склади-компонентів/ism-1800",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage-storage-ism-1100",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения/автоматизированные-склады-компонентов/ism-1100",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/автоматизовані-склади-компонентів/ism-1100",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage-storage-ism-500",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения/автоматизированные-склады-компонентов/ism-500",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/автоматизовані-склади-компонентів/ism-500",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage-storage-ism-400",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения/автоматизированные-склады-компонентов/ism-400",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/автоматизовані-склади-компонентів/ism-400",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage-storage-ism-material-incomin-station",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения/автоматизированные-склады-компонентов/ism-станция-регистрации",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/автоматизовані-склади-компонентів/ism-станція-реєстрації",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage-dry",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения/шкафы-сухого-хранения",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/шафи-сухого-зберігання",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage-dry-xsc-300-01",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения/шкафы-сухого-хранения/xsc-300-01",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/шафи-сухого-зберігання/xsc-300-01",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage-dry-xsc-600-01",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения/шкафы-сухого-хранения/xsc-600-01",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/шафи-сухого-зберігання/xsc-600-01",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage-dry-xsc-900-01",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения/шкафы-сухого-хранения/xsc-900-01",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/шафи-сухого-зберігання/xsc-900-01",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage-dry-xtc-510-01",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения/шкафы-сухого-хранения/xtc-510-01",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/шафи-сухого-зберігання/xtc-510-01",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage-paste",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения/хранение-паяльной-пасты",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/зберігання-паяльної-пасти",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-storage-paste-xs-line",
        "ru": "оборудование-для-монтажа/автоматизированные-системы-хранения/хранение-паяльной-пасты/xs-line",
        "ua": "ua/обладнання-для-монтажу/автоматизовані-системи-зберігання/зберігання-паяльної-пасти/xs-line",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-filtration",
        "ru": "оборудование-для-монтажа/дымоулавливающие-системы",
        "ua": "ua/обладнання-для-монтажу/системи-уловлювання-диму",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-filtration-solder-small",
        "ru": "оборудование-для-монтажа/дымоулавливающие-системы/дымоуловители-для-пайки",
        "ua": "ua/обладнання-для-монтажу/системи-уловлювання-диму/уловлювання-диму-для-паяння",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-filtration-solder-small-v200",
        "ru": "оборудование-для-монтажа/дымоулавливающие-системы/дымоуловители-для-пайки/v200",
        "ua": "ua/обладнання-для-монтажу/системи-уловлювання-диму/уловлювання-диму-для-паяння/v200",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-filtration-solder-small-v250",
        "ru": "оборудование-для-монтажа/дымоулавливающие-системы/дымоуловители-для-пайки/v250",
        "ua": "ua/обладнання-для-монтажу/системи-уловлювання-диму/уловлювання-диму-для-паяння/v250",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-filtration-solder-small-v350e",
        "ru": "оборудование-для-монтажа/дымоулавливающие-системы/дымоуловители-для-пайки/v350e",
        "ua": "ua/обладнання-для-монтажу/системи-уловлювання-диму/уловлювання-диму-для-паяння/v350e",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-filtration-solder-small-fumekart",
        "ru": "оборудование-для-монтажа/дымоулавливающие-системы/дымоуловители-для-пайки/fumekart",
        "ua": "ua/обладнання-для-монтажу/системи-уловлювання-диму/уловлювання-диму-для-паяння/fumekart",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-filtration-solder-big",
        "ru": "оборудование-для-монтажа/дымоулавливающие-системы/вытяжки-для-пайки",
        "ua": "ua/обладнання-для-монтажу/системи-уловлювання-диму/витяжки-для-паяння",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-filtration-solder-big-bofa-v-1000-iq",
        "ru": "оборудование-для-монтажа/дымоулавливающие-системы/вытяжки-для-пайки/v-1000-iq",
        "ua": "ua/обладнання-для-монтажу/системи-уловлювання-диму/витяжки-для-паяння/v-1000-iq",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-filtration-solder-big-bofa-v-4000",
        "ru": "оборудование-для-монтажа/дымоулавливающие-системы/вытяжки-для-пайки/v-4000",
        "ua": "ua/обладнання-для-монтажу/системи-уловлювання-диму/витяжки-для-паяння/v-4000",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-filtration-laser",
        "ru": "оборудование-для-монтажа/дымоулавливающие-системы/вытяжки-для-лазерных-станков",
        "ua": "ua/обладнання-для-монтажу/системи-уловлювання-диму/витяжки-для-лазерних-верстатів",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-filtration-laser-bofa-ad-access",
        "ru": "оборудование-для-монтажа/дымоулавливающие-системы/вытяжки-для-лазерных-станков/ad-access",
        "ua": "ua/обладнання-для-монтажу/системи-уловлювання-диму/витяжки-для-лазерних-верстатів/ad-access",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-filtration-laser-bofa-aad-fusion",
        "ru": "оборудование-для-монтажа/дымоулавливающие-системы/вытяжки-для-лазерных-станков/ad-fusion",
        "ua": "ua/обладнання-для-монтажу/системи-уловлювання-диму/витяжки-для-лазерних-верстатів/ad-fusion",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire",
        "ru": "оборудование-для-монтажа/обработка-провода",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire-stripping",
        "ru": "оборудование-для-монтажа/обработка-провода/машины-зачистки-проводов",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу/машини-зняття-ізоляції",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire-stripping/ks-w33",
        "ru": "оборудование-для-монтажа/обработка-провода/машины-зачистки-проводов/ks-w33",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу/машини-зняття-ізоляції/ks-w33",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire-stripping/ks-w336",
        "ru": "оборудование-для-монтажа/обработка-провода/машины-зачистки-проводов/ks-w336",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу/машини-зняття-ізоляції/ks-w336",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire-stripping/ks-w501",
        "ru": "оборудование-для-монтажа/обработка-провода/машины-зачистки-проводов/ks-w501",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу/машини-зняття-ізоляції/ks-w501",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire-crimping",
        "ru": "оборудование-для-монтажа/обработка-провода/оборудование-опрессовки-контактов",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу/обладнання-обжимання-контактів",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire-crimping/ks-t808",
        "ru": "оборудование-для-монтажа/обработка-провода/оборудование-опрессовки-контактов/ks-t808",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу/обладнання-обжимання-контактів/ks-t808",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire-crimping/ks-t903",
        "ru": "оборудование-для-монтажа/обработка-провода/оборудование-опрессовки-контактов/ks-t903",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу/обладнання-обжимання-контактів/ks-t903",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire-crimping/ks-t930",
        "ru": "оборудование-для-монтажа/обработка-провода/оборудование-опрессовки-контактов/ks-t930",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу/обладнання-обжимання-контактів/ks-t930",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire-stripping-cutting",
        "ru": "оборудование-для-монтажа/обработка-провода/оборудование-резки-зачистки-проводов",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу/обладнання-різання-зачищення-проводів",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire-stripping-cutting/ks-w602",
        "ru": "оборудование-для-монтажа/обработка-провода/оборудование-резки-зачистки-проводов/ks-w602",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу/обладнання-різання-зачищення-проводів/ks-w602",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire-stripping-cutting/ks-w604",
        "ru": "оборудование-для-монтажа/обработка-провода/оборудование-резки-зачистки-проводов/ks-w604",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу/обладнання-різання-зачищення-проводів/ks-w604",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire-stripping-cutting/ks-w606",
        "ru": "оборудование-для-монтажа/обработка-провода/оборудование-резки-зачистки-проводов/ks-w606",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу/обладнання-різання-зачищення-проводів/ks-w606",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire-stripping-cutting/ks-w611",
        "ru": "оборудование-для-монтажа/обработка-провода/оборудование-резки-зачистки-проводов/ks-w611",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу/обладнання-різання-зачищення-проводів/ks-w611",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire-stripping-cutting/ks-w6231",
        "ru": "оборудование-для-монтажа/обработка-провода/оборудование-резки-зачистки-проводов/ks-w6231",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу/обладнання-різання-зачищення-проводів/ks-w6231",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-wire-stripping-cutting/ks-w1022",
        "ru": "оборудование-для-монтажа/обработка-провода/оборудование-резки-зачистки-проводов/ks-w1022",
        "ua": "ua/обладнання-для-монтажу/обробка-проводу/обладнання-різання-зачищення-проводів/ks-w1022",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-dispensing",
        "ru": "оборудование-для-монтажа/дозаторы-паяльной-пасты",
        "ua": "ua/обладнання-для-монтажу/дозатори-паяльної-пасти",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-dispensing-semi-automatic",
        "ru": "оборудование-для-монтажа/дозаторы-паяльной-пасты/автомат-дозирования",
        "ua": "ua/обладнання-для-монтажу/дозатори-паяльної-пасти/автомат-дозування",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-dispensing-semi-automatic-lmdispense",
        "ru": "оборудование-для-монтажа/дозаторы-паяльной-пасты/автомат-дозирования/lmdispense",
        "ua": "ua/обладнання-для-монтажу/дозатори-паяльної-пасти/автомат-дозування/lmdispense",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-dispensing-automatic",
        "ru": "оборудование-для-монтажа/дозаторы-паяльной-пасты/автоматический-дозатор",
        "ua": "ua/обладнання-для-монтажу/дозатори-паяльної-пасти/автоматичний-дозатор",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-dispensing-automatic-sigma",
        "ru": "оборудование-для-монтажа/дозаторы-паяльной-пасты/автоматический-дозатор/sigma-dispenser",
        "ua": "ua/обладнання-для-монтажу/дозатори-паяльної-пасти/автоматичний-дозатор/sigma-dispenser",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-dispensing-automatic-delta-6",
        "ru": "оборудование-для-монтажа/дозаторы-паяльной-пасты/автоматический-дозатор/delta-6-dispenser",
        "ua": "ua/обладнання-для-монтажу/дозатори-паяльної-пасти/автоматичний-дозатор/delta-6-dispenser",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-dispensing-manual",
        "ru": "оборудование-для-монтажа/дозаторы-паяльной-пасты/ручные-дозаторы",
        "ua": "ua/обладнання-для-монтажу/дозатори-паяльної-пасти/ручні-дозатори",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-dispensing-manual-th-2004k",
        "ru": "оборудование-для-монтажа/дозаторы-паяльной-пасты/ручные-дозаторы/th-2004k",
        "ua": "ua/обладнання-для-монтажу/дозатори-паяльної-пасти/ручні-дозатори/th-2004k",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-dispensing-manual-th-206m",
        "ru": "оборудование-для-монтажа/дозаторы-паяльной-пасты/ручные-дозаторы/th-206m",
        "ua": "ua/обладнання-для-монтажу/дозатори-паяльної-пасти/ручні-дозатори/th-206m",
        "en": "en/machines",
        "lang": ""
    },


    {
        "id": "machines-coating",
        "ru": "оборудование-для-монтажа/влагозащита-электроники",
        "ua": "ua/обладнання-для-монтажу/захист-електроніки",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "machines-inspection",
        "ru": "оборудование-для-монтажа/оптическая-инспекция",
        "ua": "ua/обладнання-для-монтажу/оптична-інспекція",
        "en": "en/machines",
        "lang": ""
    },
    {
        "id": "lines",
        "ru": "комплексные-решения",
        "ua": "ua/комплексні-рішення",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-smt",
        "ru": "комплексные-решения/линия-поверхностного-монтажа",
        "ua": "ua/комплексні-рішення/лінія-поверхневого-монтажу",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-smt-prototyping",
        "ru": "комплексные-решения/линия-поверхностного-монтажа/линия-smt",
        "ua": "ua/комплексні-рішення/лінія-поверхневого-монтажу/лінія-smt",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-smt-small",
        "ru": "комплексные-решения/линия-поверхностного-монтажа/линия-smd-монтажа",
        "ua": "ua/комплексні-рішення/лінія-поверхневого-монтажу/лінія-smd-монтажу",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-smt-serial",
        "ru": "комплексные-решения/линия-поверхностного-монтажа/smt-линия-монтажа-плат",
        "ua": "ua/комплексні-рішення/лінія-поверхневого-монтажу/smt-лінія-монтажу-плат",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-smt-industry-40",
        "ru": "комплексные-решения/линия-поверхностного-монтажа/линия-smt-industry-40",
        "ua": "ua/комплексні-рішення/лінія-поверхневого-монтажу/лінія-smt-industry-40",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-tht",
        "ru": "комплексные-решения/линия-выводного-монтажа",
        "ua": "ua/комплексні-рішення/лінія-монтажу-в-отвори",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-tht-manual",
        "ru": "комплексные-решения/линия-выводного-монтажа/ручной-монтаж-компонентов",
        "ua": "ua/комплексні-рішення/лінія-монтажу-в-отвори/ручний-монтаж-компонентів",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-tht-automatic",
        "ru": "комплексные-решения/линия-выводного-монтажа/автоматический-монтаж-компонентов",
        "ua": "ua/комплексні-рішення/лінія-монтажу-в-отвори/автоматичний-монтаж-компонентів",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-coating",
        "ru": "комплексные-решения/линия-покрытия-печатных-плат",
        "ua": "ua/комплексні-рішення/лінія-покриття-друкованих-плат",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-coating-selective",
        "ru": "комплексные-решения/линия-покрытия-печатных-плат/селективное-нанесение",
        "ua": "ua/комплексні-рішення/лінія-покриття-друкованих-плат/селективне-нанесення",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-coating-dip",
        "ru": "комплексные-решения/линия-покрытия-печатных-плат/нанесение-погружением",
        "ua": "ua/комплексні-рішення/лінія-покриття-друкованих-плат/нанесення-зануренням",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-inspection",
        "ru": "комплексные-решения/линия-оптической-инспекции",
        "ua": "ua/комплексні-рішення/лінія-оптичної-інспекції",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-inspection-off-line",
        "ru": "комплексные-решения/линия-оптической-инспекции/отдельная-линия",
        "ua": "ua/комплексні-рішення/лінія-оптичної-інспекції/окрема-лінія",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-software",
        "ru": "комплексные-решения/программное-обеспечение",
        "ua": "ua/комплексні-рішення/програмне-забезпечення",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-software-janets",
        "ru": "комплексные-решения/программное-обеспечение/janets",
        "ua": "ua/комплексні-рішення/програмне-забезпечення/janets",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-software-trace-monitor",
        "ru": "комплексные-решения/программное-обеспечение/trace-monitor",
        "ua": "ua/комплексні-рішення/програмне-забезпечення/trace-monitor",
        "en": "en/lines",
        "lang": ""
    },
    {
        "id": "lines-software-ifs-nx",
        "ru": "комплексные-решения/программное-обеспечение/ifs-nx",
        "ua": "ua/комплексні-рішення/програмне-забезпечення/ifs-nx",
        "en": "en/lines",
        "lang": ""
    },

    {
        "id": "materials",
        "ru": "материалы-для-smt",
        "ua": "ua/матеріали-для-smt",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder",
        "ru": "материалы-для-smt/паяльная-паста-и-припой",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-paste",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/паяльная-паста",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/паяльна-паста",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-paste-pb-free",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/паяльная-паста/бессвинцовая-паста",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/паяльна-паста/безсвинцева-паста",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-paste-pb-free-indium-89hf",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/паяльная-паста/бессвинцовая-паста/indium-89hf",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/паяльна-паста/безсвинцева-паста/indium-89hf",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-paste-ws",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/паяльная-паста/водосмываемая-паяльная-паста",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/паяльна-паста/водозмивана-паяльна-паста",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-paste-ws-indium-63",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/паяльная-паста/водосмываемая-паяльная-паста/indium-63",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/паяльна-паста/водозмивана-паяльна-паста/indium-63",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-paste-nc",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/паяльная-паста/безотмывочная-паяльная-паста",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/паяльна-паста/безвідмивна-паяльна-паста",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-paste-nc-indium-smq92j",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/паяльная-паста/безотмывочная-паяльная-паста/indium-nc-smq92j",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/паяльна-паста/безвідмивна-паяльна-паста/indium-nc-smq92j",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-wire",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/припой-для-пайки",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/припій-для-паяння",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-wire-core",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/припой-для-пайки/припой-трубчатый",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/припій-для-паяння/припій-трубчастий",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-wire-core-pf604-r",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/припой-для-пайки/припой-трубчатый/pf604-r",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/припій-для-паяння/припій-трубчастий/pf604-r",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-wire-core-pf606-r",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/припой-для-пайки/припой-трубчатый/pf606-r",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/припій-для-паяння/припій-трубчастий/pf606-r",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-wire-core-rh63-a",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/припой-для-пайки/припой-трубчатый/rh63-a",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/припій-для-паяння/припій-трубчастий/rh63-a",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-wire-bar",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/припой-для-пайки/припой-для-волновой-пайки",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/припій-для-паяння/припій-для-хвильової-пайки",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-wire-bar-bars",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/припой-для-пайки/припой-для-волновой-пайки/solder-bar",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/припій-для-паяння/припій-для-хвильової-пайки/solder-bar",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-wire-preform",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/припой-для-пайки/припой-в-виде-фольги-преформ",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/припій-для-паяння/припій-у-фользі-преформах",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-wire-preforms",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/припой-для-пайки/припой-в-виде-фольги-преформ/solder-preforms",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/припій-для-паяння/припій-у-фользі-преформах/solder-preforms",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-wire-preforms-foil",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/припой-для-пайки/припой-в-виде-фольги-преформ/solder-foil",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/припій-для-паяння/припій-у-фользі-преформах/solder-foil",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-flux",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/флюс-для-пайки",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/флюс-для-паяння",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-flux-gel",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/флюс-для-пайки/флюс-гель",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/флюс-для-паяння/флюс-гель",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-flux-gel-smf",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/флюс-для-пайки/флюс-гель/smf",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/флюс-для-паяння/флюс-гель/smf",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-flux-wave",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/флюс-для-пайки/флюс-для-пайки-волной",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/флюс-для-паяння/флюс-для-паяння-хвилею",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-solder-flux-wave-evf-2310",
        "ru": "материалы-для-smt/паяльная-паста-и-припой/флюс-для-пайки/флюс-для-пайки-волной/evf-2310",
        "ua": "ua/матеріали-для-smt/матеріали-для-паяння/флюс-для-паяння/флюс-для-паяння-хвилею/evf-2310",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning",
        "ru": "материалы-для-smt/жидкости-для-мойки",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-analysis",
        "ru": "материалы-для-smt/жидкости-для-мойки/анализ-чистоты-поверхности-плат",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/аналіз-чистоти-поверхні-плат",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-pcba",
        "ru": "материалы-для-smt/жидкости-для-мойки/жидкости-для-отмывки-печатных-плат",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/рідини-для-відмивання-друкованих-плат",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-pcba-us",
        "ru": "материалы-для-smt/жидкости-для-мойки/жидкости-для-отмывки-печатных-плат/для-ультразвуковой-отмывки-плат",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/рідини-для-відмивання-друкованих-плат/для-ультразвукового-миття",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-pcba-us-vigon-us",
        "ru": "материалы-для-smt/жидкости-для-мойки/жидкости-для-отмывки-печатных-плат/для-ультразвуковой-отмывки-плат/vigon-us",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/рідини-для-відмивання-друкованих-плат/для-ультразвукового-миття/vigon-us",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-pcba-us-zestron-fa",
        "ru": "материалы-для-smt/жидкости-для-мойки/жидкости-для-отмывки-печатных-плат/для-ультразвуковой-отмывки-плат/zestron-fa",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/рідини-для-відмивання-друкованих-плат/для-ультразвукового-миття/zestron-fa",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-pcba-jet",
        "ru": "материалы-для-smt/жидкости-для-мойки/жидкости-для-отмывки-печатных-плат/жидкость-для-струйной-отмывки-плат",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/рідини-для-відмивання-друкованих-плат/для-струменевого-миття-плат",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-pcba-jet-vigon-a-201",
        "ru": "материалы-для-smt/жидкости-для-мойки/жидкости-для-отмывки-печатных-плат/жидкость-для-струйной-отмывки-плат/vigon-a-201",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/рідини-для-відмивання-друкованих-плат/для-струменевого-миття-плат/vigon-a-201",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-pcba-manual",
        "ru": "материалы-для-smt/жидкости-для-мойки/жидкости-для-отмывки-печатных-плат/жидкость-для-ручной-отмывки-плат",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/рідини-для-відмивання-друкованих-плат/рідина-для-ручного-миття-плат",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-pcba-manual-vigon-efm",
        "ru": "материалы-для-smt/жидкости-для-мойки/жидкости-для-отмывки-печатных-плат/жидкость-для-ручной-отмывки-плат/vigon-efm",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/рідини-для-відмивання-друкованих-плат/рідина-для-ручного-миття-плат/vigon-efm",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-machine",
        "ru": "материалы-для-smt/жидкости-для-мойки/жидкость-для-очистки-оборудования",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/рідина-для-очищення-обладнання",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-machine-vigon-rc-303",
        "ru": "материалы-для-smt/жидкости-для-мойки/жидкость-для-очистки-оборудования/vigon-rc-303",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/рідина-для-очищення-обладнання/vigon-rc-303",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-machine-atron-sp-200",
        "ru": "материалы-для-smt/жидкости-для-мойки/жидкость-для-очистки-оборудования/atron-sp-200",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/рідина-для-очищення-обладнання/atron-sp-200",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-machine-atron-dc",
        "ru": "материалы-для-smt/жидкости-для-мойки/жидкость-для-очистки-оборудования/atron-dc",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/рідина-для-очищення-обладнання/atron-dc",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-stencil",
        "ru": "материалы-для-smt/жидкости-для-мойки/жидкость-для-очистки-трафаретов",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/рідина-для-очищення-трафаретів",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-stencil-vigon-sc-200",
        "ru": "материалы-для-smt/жидкости-для-мойки/жидкость-для-очистки-трафаретов/vigon-sc-200",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/рідина-для-очищення-трафаретів/vigon-sc-200",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-stencil-vigon-uc-160",
        "ru": "материалы-для-smt/жидкости-для-мойки/жидкость-для-очистки-трафаретов/vigon-uc-160",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/рідина-для-очищення-трафаретів/vigon-uc-160",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-analitic",
        "ru": "материалы-для-smt/жидкости-для-мойки/методы-контроля-качества-отмывки-плат",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/методи-контролю-якості-миття-плат",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-analitic-bath-analyzer-10",
        "ru": "материалы-для-smt/жидкости-для-мойки/методы-контроля-качества-отмывки-плат/bath-analyzer-10",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/методи-контролю-якості-миття-плат/bath-analyzer-10",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-analitic-bath-analyzer-20",
        "ru": "материалы-для-smt/жидкости-для-мойки/методы-контроля-качества-отмывки-плат/bath-analyzer-20",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/методи-контролю-якості-миття-плат/bath-analyzer-20",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-analitic-zestron-eye",
        "ru": "материалы-для-smt/жидкости-для-мойки/методы-контроля-качества-отмывки-плат/zestron-eye",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/методи-контролю-якості-миття-плат/zestron-eye",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-analitic-zestron-eye-mobile",
        "ru": "материалы-для-smt/жидкости-для-мойки/методы-контроля-качества-отмывки-плат/zestron-eye-mobile",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/методи-контролю-якості-миття-плат/zestron-eye-mobile",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-test",
        "ru": "материалы-для-smt/жидкости-для-мойки/анализ-чистоты-поверхности-плат",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/аналіз-чистоти-поверхні-плат",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-test-zestron-flux-test",
        "ru": "материалы-для-smt/жидкости-для-мойки/анализ-чистоты-поверхности-плат/zestron-flux-test",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/аналіз-чистоти-поверхні-плат/zestron-flux-test",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-test-zestron-resin-test",
        "ru": "материалы-для-smt/жидкости-для-мойки/анализ-чистоты-поверхности-плат/zestron-resin-test",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/аналіз-чистоти-поверхні-плат/zestron-resin-test",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-cleaning-test-zestron-coating-layer-test",
        "ru": "материалы-для-smt/жидкости-для-мойки/анализ-чистоты-поверхности-плат/zestron-coating-layer-test",
        "ua": "ua/матеріали-для-smt/рідини-для-чищення/аналіз-чистоти-поверхні-плат/zestron-coating-layer-test",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-coating",
        "ru": "материалы-для-smt/защитные-покрытия",
        "ua": "ua/матеріали-для-smt/захисні-покриття",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-coating-acrylic",
        "ru": "материалы-для-smt/защитные-покрытия/влагозащитные-акриловые-покрытия",
        "ua": "ua/матеріали-для-smt/захисні-покриття/вологозахисні-акрилові-покриття",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-coating-acrylic-1b73",
        "ru": "материалы-для-smt/защитные-покрытия/влагозащитные-акриловые-покрытия/1b73",
        "ua": "ua/матеріали-для-smt/захисні-покриття/вологозахисні-акрилові-покриття/1b73",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-coating-urethan",
        "ru": "материалы-для-smt/защитные-покрытия/влагозащитные-уретановые-покрытия",
        "ua": "ua/матеріали-для-smt/захисні-покриття/вологозахисні-уретанові-покриття",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-coating-urethan-1a33",
        "ru": "материалы-для-smt/защитные-покрытия/влагозащитные-уретановые-покрытия/1a33",
        "ua": "ua/матеріали-для-smt/захисні-покриття/вологозахисні-уретанові-покриття/1a33",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-coating-silicon",
        "ru": "материалы-для-smt/защитные-покрытия/влагозащитные-силиконовые-покрытия",
        "ua": "ua/матеріали-для-smt/захисні-покриття/вологозахисні-силіконові-покриття",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-coating-silicon-1c49",
        "ru": "материалы-для-smt/защитные-покрытия/влагозащитные-силиконовые-покрытия/1c49",
        "ua": "ua/матеріали-для-smt/захисні-покриття/вологозахисні-силіконові-покриття/1c49",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-coating-uv",
        "ru": "материалы-для-smt/защитные-покрытия/покрытия-ультрафиолетового-отверждения",
        "ua": "ua/матеріали-для-smt/захисні-покриття/покриття-ультрафіолетового-затвердіння",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-coating-uv-uv40",
        "ru": "материалы-для-smt/защитные-покрытия/покрытия-ультрафиолетового-отверждения/uv40",
        "ua": "ua/матеріали-для-smt/захисні-покриття/покриття-ультрафіолетового-затвердіння/uv40",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-coating-rubber",
        "ru": "материалы-для-smt/защитные-покрытия/синтетическая-резина-для-плат",
        "ua": "ua/матеріали-для-smt/захисні-покриття/синтетична-гума-для-плат",
        "en": "en/materials",
        "lang": ""
    },
    {
        "id": "materials-coating-rubber-1b51",
        "ru": "материалы-для-smt/защитные-покрытия/синтетическая-резина-для-плат/1b51",
        "ua": "ua/матеріали-для-smt/захисні-покриття/синтетична-гума-для-плат/1b51",
        "en": "en/materials",
        "lang": ""
    },

    {
        "id": "service",
        "ru": "техническое-обслуживание",
        "ua": "ua/технічне-обслуговування",
        "en": "en/service",
        "lang": ""
    },
    {
        "id": "service-installation",
        "ru": "техническое-обслуживание/пуско-наладка",
        "ua": "ua/технічне-обслуговування/пуско-наладка",
        "en": "en/service",
        "lang": ""
    },
    {
        "id": "service-support",
        "ru": "техническое-обслуживание/техническая-поддержка",
        "ua": "ua/технічне-обслуговування/технічна-підтримка",
        "en": "en/service",
        "lang": ""
    },
    {
        "id": "service-training",
        "ru": "техническое-обслуживание/техническое-обучение",
        "ua": "ua/технічне-обслуговування/технічне-навчання",
        "en": "en/service",
        "lang": ""
    },
    {
        "id": "contact-form-request",
        "ru": "contact-us-form",
        "ua": "contact-us-form-ua",
        "en": "/",
        "lang": ""
    },
    {
        "id": "company",
        "ru": "компания",
        "ua": "ua/компанія",
        "en": "en/company",
        "lang": ""
    },
    {
        "id": "company-about-ua",
        "ru": "компания/о-нас",
        "ua": "ua/компанія/про-нас",
        "en": "en/company/about-us",
        "lang": ""
    },
    {
        "id": "company-careers",
        "ru": "компания/вакансии",
        "ua": "ua/компанія/вакансії",
        "en": "en/company/careers",
        "lang": ""
    },
    {
        "id": "company-contacts",
        "ru": "компания/контакты",
        "ua": "ua/компанія/контакти",
        "en": "en/company/contacts",
        "lang": ""
    },
    {
        "id": "company-faq",
        "ru": "компания/вопросы",
        "ua": "ua/компанія/питання",
        "en": "en/company/faq",
        "lang": ""
    },
    {
        "id": "company-partnership",
        "ru": "компания/партнерство",
        "ua": "ua/компанія/партнерство",
        "en": "en/company/partnership",
        "lang": ""
    },
    {
        "id": "company-history",
        "ru": "компания/история",
        "ua": "ua/компанія/історія",
        "en": "en/company/history",
        "lang": ""
    }
];